<template>
    <Forgotlink ref="forgotlinkPopup"></Forgotlink>

     


     <div class="popups" :class="{ 'show-popups': isPopupVisible }">
                  <div class="popups-content" style="text-align:center;">
            
                  <p style="color:rgb(92, 42, 218);">Login Success!</p>

            
                  </div>
              </div>
    <popup-modal ref="popup">
        
            <div class="container-fluid">
                <div id="reg-login">
                    <div class="wrappers">

               <div class='row' v-if="!successActive">
                        
                  <div class="alert alert-success alert-block">
                      <button type="button" class="close" @click="successactivealertclose()" data-dismiss="alert" style=" float: right; ">×</button>
                      <strong style="text-align: center; align-items: center; display: block; ">Your Account Successfully Activated</strong> 
                  </div>
        
              </div>  

              <div class='row' v-if="!errorActive">
        
                      <div class="alert alert-danger alert-block">
                          <button type="button" class="close" @click="erroractivealertclose()" data-dismiss="alert" style=" float: right; ">×</button>
                          <strong style="text-align: center; align-items: center; display: block; ">Your Account Not Activated </strong> 
                      </div>
        
                  </div>

              <div class='row' v-if="!successregistration">
                 <div class="alert alert-success alert-block">
                      <button type="button" class="close" @click="registrationalertclose()" data-dismiss="alert" style=" float: right; ">×</button>
                      <strong style="text-align: center; align-items: center; display: block; ">Your account has been registered! <br/>
    Please check your registered email for email verification</strong> 
                  </div>
              </div>
              <div class='row' v-if="!showerrors">
                  <div class="alert alert-danger alert-block">
                 <!--     <button type="button" class="close" @click="loginalertclose()" data-dismiss="alert" style=" float: right; ">×</button> -->
                      <strong style="text-align: center; align-items: center; display: block; "> Invalid user name / Password </strong> 
                  </div>
              </div>

          

              <div class="row mb-2">
                    <div class="col">
                        <span style=" font-size: 20px; font-weight: 500; " v-show="showlogin" >Sign in</span>
                        <span style=" font-size: 20px; font-weight: 500; " v-show="showregistartion" >Sign up</span>
                    </div>
                    <div class="col" id="login-icon">
                        <a @click="_cancel">
                            <i class="fa-solid fa-xmark"></i>
                        </a>
                    </div>
              </div>


              

              <div class="row justify-content-md-center justify-content-sm-center mt-1">
                <div class="col-6 col-md-6 col-sm-6 border   login-class" :class="{ 'selected-login': showlogin }" 
                @click="showlogin = true; showregistartion = false">
                  <a @click="showlogin = true; showregistartion = false"
                              >Login
                      </a>
                </div>
                <div class="col-6 col-md-6 col-sm-6 border  login-class" :class="{ 'selected-login': showregistartion }"
                @click="showlogin = false; showregistartion = true; showerrors = true;">
                  <a   @click="showlogin = false; showregistartion = true; showerrors = true;"
                                >Registration
                        </a>
                </div>
            
              </div>
          
         


              <div class="login-forms" id="log-form" v-show="showlogin">

                <p v-if="errors" class="error">
                    {{ errormessage }}
                </p>
                <form method="post" @submit.prevent="login" class=" vl-parent" ref="formContainer">

                  <div class="input-box">
                    <input type="text" placeholder="Enter your email" v-model="loginData.email" required>
                  </div>
                  <div class="input-box">
                    <input type="password" placeholder="Enter password" v-model="loginData.password"
                           autocomplete="current-password" required>
                  </div>
              
                  <div class="input-box button">
                    <input type="Submit" value=" SignIn" style="background:#00aeea;">
                  </div>
              
                  <div class="horizontalline">
                    <div>
                      <a href="javascript:void(0);" @click="clickforgotLinkPopup" class="forgot-email">Forgot Password</a>
                    </div>
               
                  </div>
                  <!--
              <div class="socialmediaicon">
                <div class="twitter">
                  <a href="#"><i class="fab fa-twitter"></i> <span>Sign in With Twitter</span></a>
                </div>
                <div class="facebook">
                  <a href="#"><i class="fab fa-facebook-f"></i><span>Sign in With Facebook</span></a>
                </div>
                <div class="google">
                  <a href="#"><i class="fab fa-google-f"></i><span>Sign in With Google Account</span></a>
                </div>


              </div>

              -->
                </form>
              </div>


              <!--Registration pop up-->

              <div class="login-forms" id="reg-form" v-show="showregistartion">

                <form @submit.prevent="submitCustomerRegistrationForm" class="vl-parent" ref="formContainer">
                  <div class="input-box">
                    <input type="text" id="first_name" name="first_name" v-model="formData.first_name"
                           placeholder="Enter your First name" required>
                  </div>
                  <div class="input-box">
                    <input type="text" id="last_name" name="last_name" v-model="formData.last_name"
                           placeholder="Enter your Last name" required>
                  </div>
                  <div class="input-box">
                    <span v-if="registrererror" class="error-message mb-2">This Email already has been taken</span>
                    <input type="email" id="email" placeholder="Enter Your Email" v-model="formData.email" name="email"
                           required>
                
                  </div>
                  <div :class="['input-box', { 'error-validation': registrererror }]">
                     <span v-if="!passworderror" class="error-message mb-3">Password must be 6 letters</span>
                    <input type="password" id="password" name="password" v-model="formData.password"
                           autocomplete="new-password" placeholder="Create password" required>
                  </div>
                  <div :class="['input-box', { 'error-validation': !passworderror }]">
                     <span v-if="submitted && !isPasswordMatch" class="error-message mb-2">Passwords do not match</span>
                    <input type="password" placeholder="Confirm password" autocomplete="new-password" v-model="formData.confirm_password">
               
                  </div>
                  <div :class="['input-box', { 'error-validation': submitted && !isPasswordMatch }]">
                    <input type="text" id="mobile_number" name="mobile_number" v-model="formData.mobile_number"
                           placeholder="Enter Mobile Number" required>
                    <span v-if="!isValidMobileNumber">Please enter a valid mobile number.</span>
                  </div>
                  <div class="input-box">
                    <select id="language" name="language" v-model="formData.language" class="input-box mt-2">
                        
                        <option value="en">English</option>
                        <option value="fr">French</option>
                        <option value="du">Dutch</option>
                        <!-- Add more language options as needed -->
                    </select>
                  </div>
                
              

                  <div class="policy">
                    <input type="checkbox" required>
                    <h3>I accept all terms & condition</h3>
                  </div>
                  <div class="input-box button">
                    <input type="Submit" value="Register Now" style="background:#00aeea;">
                  </div>

                  <!--
              <div class="horizontalline">
                <p>OR</p>
              </div>
              <div class="socialmediaicon">
                <div class="twitter">
                  <a href="#"><i class="fab fa-twitter"></i> <span>Sign in With Twitter</span> </a>
                </div>
                <div class="facebook">
                  <a href="#"><i class="fab fa-facebook-f"></i><span>Sign in With Facebook </span></a>
                </div>
                <div class="google">
                  <a href="#"><i class="fab fa-google-f"></i><span>Sign in With Google Account</span></a>
                </div>
              </div>
              -->
                </form>
              </div>

            </div>
                </div>
            </div>
    </popup-modal>
    <!--pop up reg/login form start-->
   

<!--pop up reg/login form end-->
</template>

<script>
import PopupModal from './PopupModal.vue'
//import {  ref } from 'vue';
//import { RouterLink } from "vue-router";
//import { useStore } from 'vuex';
import Forgotlink from '../components/CustomerForgotLinksend.vue';
//import router from '../router/index';
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
    name: 'ConfirmDialogue',

    components: { 
    PopupModal,
    Forgotlink
   //  RouterLink,
    
    },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'Cancel', // text for cancel button

        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
        formData: {
            first_name: '',
            last_name: '',
            language: '',
            email: '',
            password: '',
            mobile_number: '',
            confirm_password: ''
        },
        loginData: {
            'email': '',
            'password': ''
        },
        isEmailValid: false, // Add this property for email validation
        showlogin: true,
        showregistartion: false,
        username: '',
        showerrors: true,
        errors: '',
        registrererror: '',
        itemstest: [],
        isValidMobileNumber: true,
        password: '',
        successregistration: true,
        submitted: false,
        loginuser: '',
        passworderror: true,
        successActive: true,
        errorActive: true,
        isPopupVisible: false,
        timer: null,
        showLoadingicon:false,
         fullPage: true,
    }),

    computed: {
        isPasswordMatch() {
            return this.formData.password === this.formData.confirm_password;
        },
        tokenId() {
            return this.$route.params.id;
        },
    },

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

         resetRegForm() {
          this.formData = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirm_password: '',
            mobile_number: '',
            language: '',
          };
        },
        resetLogForm() {
          this.loginData = {
            'email': '',
            'password': ''
          };
        },

        _confirm() {

          this.showerrors = true;
          this.successregistration = true;
          this.successActive = true;
          this.errorActive = true;
          this.registrererror = '';
          this.passworderror = true;
          this.resetRegForm();
          this.resetLogForm();
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.showerrors = true;
            this.successregistration = true;
            this.successActive = true;
            this.errorActive = true;
            this.registrererror = '';
            this.passworderror = true;
            this.resetRegForm();
            this.resetLogForm();
           
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User canceled the dialogue'))
        },

        async login() {
            this.successregistration = true;
            let loader = this.$loading.show({
              // Optional parameters
              container: this.fullPage ? null : this.$refs.formContainer,
              canCancel: true,
              onCancel: this.onCancel,
            });

            try {
                const response = await axios.post('customer/token', this.loginData)
                 loader.hide();
                if (response.data.error) {
                    this.errors = response.data.error;
                    this.showlogin = true;
                    this.showregistartion = false;
                    this.showerrors = false;
                    sessionStorage.setItem('loggedIn', false);
                    sessionStorage.setItem('isUserLoggedIn', false);
                } else {

                    if (response.data.data == 'error') {
                        this.showerrors = true;
                        this.errorActive = false;
                    } else {
                        this.showerrors = true;
                        this.loginuser = response.data;
                        this.showlogin = false;
                        this.showregistartion = false;
                        this.$store.commit('login', { userId: this.loginuser.id });
                        // Store the bearer token in session storage
                        sessionStorage.setItem('bearerToken', response.data.data.token);

                        sessionStorage.setItem('bearerToken', response.data.data.token);
                        sessionStorage.setItem('loggedIn', true);
                        sessionStorage.setItem('userId', this.loginuser.id);
                        sessionStorage.setItem('isUserLoggedIn', true);

                        this._cancel();
                          this.isPopupVisible = true; // Show the popup
                          // Start a timer to hide the popup and navigate after 10 seconds
                          this.timer = setTimeout(() => {
                            this.isPopupVisible = false; // Hide the popup
                            window.location.reload(); // Navigate to '/vendordashboard'
                          }, 1500);
                    }

                }

                // this.successregistration = false;
                //console.log('success');
            } catch (error) {
               loader.hide();
                this.showerrors = false;
            }

        },
        async submitCustomerRegistrationForm() {

            this.submitted = true;
            console.log('formdata',this.formData);

            // Check if the passwords match and perform form submission logic

            if (this.formData.password.length > 5) {
                if (this.isPasswordMatch) {
                   let loader = this.$loading.show({
                      // Optional parameters
                      container: this.fullPage ? null : this.$refs.formContainer,
                      canCancel: true,
                      onCancel: this.onCancel,
                    });
                    try {
                        const response = await axios.post('customer/register', this.formData);
                          loader.hide();
                        if (response.data.error) {
                            // Display validation errors in Vue
                            this.errors = response.data.error;
                            console.log('error', this.errors);
                            this.showerrors = true; // Show the error messages
                            this.showlogin = false;
                            this.showregistartion = true;
                            this.successregistration = false;
                        } else {
                            this.showlogin = true;
                            this.showregistartion = false;
                            this.successregistration = false;
                        }
                    } catch (error) {
                      loader.hide();
                        if (error.response) {
                            // Handle other server-side errors here
                            this.registrererror = error.response.data.data;
                            console.error('Error responseeee:', error.response.data);
                        } else {
                            // Handle other client-side errors here
                            console.error('Error updating user profile:', error);
                        }

                    }
                }
            } else {
                this.passworderror = false;
            }

        },
        registrationalertclose() {
            

      this.successregistration = true;
     
      
      this.resetRegForm();
      this.resetLogForm();
        },
        loginalertclose() {
            this.showerrors = true;
            this.resetRegForm();
      this.resetLogForm();
        },
        successactivealertclose() {
            this.successActive = true;
            this.resetRegForm();
      this.resetLogForm();
        },
        erroractivealertclose() {
            this.errorActive = true;
            this.resetRegForm();
      this.resetLogForm();
        },
        async activatedaccount(id) {



            try {
                const response = await axios.post(`vendor/accountactive/${id}`);
                console.log(response.data);
                if (response.data.data === 'success') {
                    this.showerrors = true;
                    this.successActive - false;
                    //router.push('/vendorlogin');
                    //window.reload();
                    //console.log('success');
                } else if (response.data.data === 'error') {
                    this.showerrors = true;
                    this.errorActive = false;
                    // Handle email error response
                    // this.mailerror = false;
                    // console.log('error');
                }

            } catch (error) {
                console.error('Error updating user profile:', error);
            }


            // Check if the passwords match and perform form submission logic

        },
         async clickforgotLinkPopup() {
            this.$refs.popup.close()
            this.resolvePromise(false)
            await this.$refs.forgotlinkPopup.show({

            })

        },

    },
     mounted() {

      if(Cookies.get("CookiesLanguageCode")){
        this.formData.language = Cookies.get("CookiesLanguageCode"); 
       }else{
        this.formData.language = 'en';
       }

       if (this.tokenId) {
            // console.log(this.tokenId);
            this.activatedaccount(this.tokenId);
        }

    }
}
</script>



<style scoped>
.popups {
     display: none;
    background-color: rgba(136, 131, 131, 0.5);
    justify-content: center;
    align-items: center;
    top: 10px;
    z-index: 9;
}
.show-popups {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popups-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.ok-btn {
    color: red;
    text-decoration: underline;
    line-height: 2.5rem;
    cursor: pointer;
}

.cancel-btn {
    padding: 0.5em 1em;
    background-color: #d5eae7;
    color: #35907f;
    border: 2px solid #0ec5a4;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}

.forgot-email{
  text-decoration: underline;
  color:#00aeea;
  cursor: pointer;
}

@media screen and (max-width: 486px){
.login-class a{
  font-size: 18px !important;
  text-decoration: none;
  cursor: pointer;
 
}
}
#login-icon{
  text-align: end;
  
}
#login-icon i{
  font-size: 27px;
}
.login-class{
      text-align: center;
    height: 61px;
    padding-top: 11px;
     background: white;
}

.login-class:hover{
 color:#00aeea;
  border-bottom:2px solid #00aeea !important;
}
.selected-login{
  color: #00aeea;
  border-bottom:2px solid #00aeea !important;
}
.login-class a{
  font-size: 19px;
  text-decoration: none;
  cursor: pointer;
 
}
.login-button{
  background: rgb(248, 243, 243);
  
}
.error-validation {
    margin-top: 46px !important;
}

.error-message {
    color: red;
}



#reg-login .wrappers ul li {
    display: inline-block;

    text-align: center;

    font-size: 25px;
    cursor: pointer;
}

#reg-login .wrappers ul li:last-child {
    position: absolute;
    margin-top: 0px;
    margin-left: 36px;
    font-size: 35px;
}

.btn1 {
    height: 3.5rem;
    padding: 0 2rem;
    border: 0.1rem solid #000;
    border-radius: 3.12rem;
    box-shadow: 0 0.4rem 0 0 #000;
    background-color: #fff;
    color: #000;
    font-family: "Epilogue", sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s;

}

.selected {
    border: 0.1rem solid #00aeea;
}

.btn1:hover {
    transform: translateY(0.3rem);
    box-shadow: 0 0.2rem 0 0 #000;
}
</style>