export default {
    computed: {
        baseUrl() {
            // Replace this with your actual base URL
            return 'https://admin-stage.vanakkamtamila.com/';
        },
        default_userImage(){
            return require('@/assets/images/user_avatar.jpeg');
        }
    }
}; 