import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'HomeContent',
            component: () => import('../HomeContent/HomePage.vue'),
            props: true
        },
        {
            path: '/:id',
            name: 'HomeContentUserActive',
            component: () => import('../HomeContent/HomePage.vue'),
            props: true
        },
        {
            path: '/vendor/:id',
            name: 'HomeContentVendorActive',
            component: () => import('../HomeContent/HomePageVendor.vue'),
            props: true
        },
        {
            path: '/vendor/successactive',
            name: 'HomeContentVendorActiveSuccess',
            component: () => import('../HomeContent/HomepageVendorAccountSuccess.vue'),
            props: true
        },
        {
            path: '/event',
            name: 'EventContent',
            component: () => import('../Event/EventView.vue'),
        },
        {
            path: '/stores',
            name: 'StoreContent',
            component: () => import('../stores/StoreView.vue'),
        },
       
        {
            path: '/user',
            name: 'UserDashboard',
            component: () => import('../UserDashboard/userDash.vue'),
            children: [
                {
                    path: 'dashboard',
                    name: 'vendorDashboardContent',
                    component: () => import('../UserDashboard/wishList.vue'),
                },
                {
                    path: 'profile',
                    name: 'userProfile',
                    props: true,
                    component: () => import('../UserDashboard/profileView.vue'),
                }
            ]

        },

       
        {
            path: '/vendor',
            name: 'VendorDashboard',
            component: () => import('../vendorDashboard/dashboardView.vue'),
            
            children: [
                {
                    path: 'dashboard',
                    name: 'vendorDashboardContent',
                    props: true,
                    component: () => import('../vendorDashboard/dashboardContent.vue'),
                    beforeRouteEnter(to, from, next) {
                        if (to.params.forceReload) {
                          window.location.reload();
                        } else {
                          next();
                        }
                      }
                },
                {
                    path: 'profile',
                    name: 'vendorProfile',
                    props: true,
                    component: () => import('../vendorDashboard/vendorProfile.vue'),
                    
                   
                },
                
                {
                    path: 'business',
                    name: 'vendorBusiness',
                    component: () => import('../vendorDashboard/vendorBusiness.vue'),
                    
                },
                {
                    path: 'business/addbusiness',
                    name: 'addVendorbusiness',
                    component: () => import('../vendorDashboard/vendorBusiness/addBusiness.vue')
                },
                {
                    path: 'business/editbusiness/:id',
                    name: 'editVendorbusiness',
                    props: true,
                    component: () => import('../vendorDashboard/vendorBusiness/editBusiness.vue')
                },
                {
                    path: 'business/viewbusiness/:id',
                    name: 'viewVendorbusiness',
                    props: true,
                    component: () => import('../vendorDashboard/vendorBusiness/viewBusiness.vue')
                },
                {
                    path: 'business/editLanguagebusiness/:id/:language',
                    name: 'editVendorLanguagebusiness',
                    props: true,
                    component: () => import('../vendorDashboard/vendorBusiness/languageBusiness.vue')
                },
               
                
                {
                    path: 'products',
                    name: 'vendorProducts',
                    component: () => import('../vendorDashboard/VendorProducts.vue')
                },
                {
                    path: 'products/addproducts',
                    name: 'addvendorProducts',
                    component: () => import('../vendorDashboard/vendorProduct/addProduct.vue')
                },
                {
                    path: 'products/editproducts/:id',
                    props: true,
                    name: 'editvendorProducts',
                    component: () => import('../vendorDashboard/vendorProduct/editProduct.vue')
                },
                {
                    path: 'products/editlanguageproducts/:id/:language',
                    props: true,
                    name: 'editvendorlanguageProducts',
                    component: () => import('../vendorDashboard/vendorProduct/languageProduct.vue')
                },
                {
                    path: 'products/viewproducts/:id',
                    props: true,
                    name: 'viewvendorProducts',
                    component: () => import('../vendorDashboard/vendorProduct/viewProduct.vue')
                },
                {
                    path: 'stores',
                    name: 'vendorStores',
                    component: () => import('../vendorDashboard/vendorStores.vue')
                },
                {
                    path: 'stores/addstores',
                    name: 'addVendorstores',
                    component: () => import('../vendorDashboard/vendorStore/addStore.vue')
                },
                {
                    path: 'stores/editstores/:id',
                    props: true,
                    name: 'editVendorstores',
                    component: () => import('../vendorDashboard/vendorStore/editStore.vue')
                },
                {
                    path: 'stores/editlanguagestores/:id/:language',
                    props: true,
                    name: 'editlanguageVendorstores',
                    component: () => import('../vendorDashboard/vendorStore/languageStore.vue')
                },
                {
                    path: 'stores/viewstores/:id',
                    props:true,
                    name: 'viewVendorstores',
                    component: () => import('../vendorDashboard/vendorStore/viewStore.vue')
                },
                {
                    path: 'stores/addaddress',
                    name: 'addVendorstoresaddress',
                    component: () => import('../vendorDashboard/vendorStore/addAddress.vue')
                },
                {
                    path: 'events',
                    name: 'vendorEvents',
                    component: () => import('../vendorDashboard/vendorEvents.vue')
                },
                {
                    path: 'events/addevents',
                    name: 'addvendorEvents',
                    component: () => import('../vendorDashboard/vendorEvent/addEvent.vue')
                },
                {
                    path: 'events/editevents/:id',
                    props: true,
                    name: 'editvendorEvents',
                    component: () => import('../vendorDashboard/vendorEvent/editEvent.vue')
                },
                {
                    path: 'events/editlanguageevents/:id/:language',
                    props: true,
                    name: 'editlanguagevendorEvents',
                    component: () => import('../vendorDashboard/vendorEvent/languageEvent.vue')
                },
                {
                    path: 'events/viewevents/:id',
                    props: true,
                    name: 'viewvendorEvents',
                    component: () => import('../vendorDashboard/vendorEvent/viewEvent.vue')
                },
                {
                    path: 'deals',
                    name: 'vendorDeals',
                    component: () => import('../vendorDashboard/vendorDeals.vue')
                },
                {
                    path: 'vendorproductreviewrequest',
                    name: 'VendorProductReviewRequest',
                    props: true,
                    component: () => import('../vendorDashboard/vendorProductReviewRequest.vue'),

                },
                 {
                    path: 'vendorproductreviewrequest/addreview',
                    props: true,
                    name: 'addReviewrequestProduct',
                    component: () => import('../vendorDashboard/productReview/addreviewRequest.vue')
                },
                {
                    path: 'vendorproductreviewrequest/addreview/:id',
                    props: true,
                    name: 'ReviewrequestProduct',
                    component: () => import('../vendorDashboard/productReview/ReviewRequest.vue')
                },
                {
                    path: 'vendorproductreviewrequest/acceptreview/:id',
                    props: true,
                    name: 'acceptReviewrequestProduct',
                    component: () => import('../vendorDashboard/productReview/acceptReview.vue')
                },
                {
                    path: 'vendoreventreviewrequest',
                    name: 'VendorEventReviewRequest',
                    props: true,
                    component: () => import('../vendorDashboard/vendorEventReviewRequest.vue'),

                },
                {
                    path: 'vendoreventreviewrequest/addreview',
                    props: true,
                    name: 'addReviewrequestEvent',
                    component: () => import('../vendorDashboard/eventReview/addreviewRequest.vue')
                },
                {
                    path: 'vendoreventreviewrequest/addreview/:id',
                    props: true,
                    name: 'ReviewrequestEvent',
                    component: () => import('../vendorDashboard/eventReview/ReviewRequest.vue')
                },
                {
                    path: 'vendoreventreviewrequest/acceptreview/:id',
                    props: true,
                    name: 'acceptReviewrequestEvent',
                    component: () => import('../vendorDashboard/eventReview/acceptReview.vue')
                },
                {
                    path: 'vendorstorereview',
                    name: 'VendorStoreReview',
                    props: true,
                    component: () => import('../vendorDashboard/vendorstoreReview.vue'),

                },
                {
                    path: 'vendorstorereview/acceptreview/:id',
                    props: true,
                    name: 'acceptVendorStoreReview',
                    component: () => import('../vendorDashboard/storeReview/acceptReview.vue')
                },
            ]

        },

        {
            path: '/product/:id',
            name: 'DetailView',
            component: () => import('../detailPage/DetailsPage.vue'),
            props: true, // Pass route params as component props
        },

        {
            path: '/event/:id',
            name: 'EventDetailView',
            component: () => import('../eventdetailPage/DetailsPage.vue'),
            props: true, // Pass route params as component props
        },
        {
            path: '/store/:id',
            name: 'StoreDetailView',
            component: () => import('../storedetailPage/DetailsPage.vue'),
            props: true, // Pass route params as component props
        },
       
        
        {
            path: '/user/passwordreset/:id',
            name: 'UserPasswordReset',
            component: () => import('../UserDashboard/passwordReset.vue'),
            props: true,
        },
       
        {
            path: '/vendor/passwordreset/:id',
            name: 'VendorPasswordReset',
            component: () => import('../vendorDashboard/passwordReset.vue'),
            props: true,
        },
        

        {
            path: '/eventreview/:id',
            name: 'eventReview',
            props: true,
            component: () => import('../vendorDashboard/sendeventReview.vue'),

        },
        {
            path: '/productreview/:id',
            name: 'productReview',
            props: true,
            component: () => import('../vendorDashboard/sendproductReview.vue'),

        },
        {
            path: '/Business/advertisement',
            name: 'BusinessAdvertisement',
            props: true,
            component: () => import('../Footer/Business/AdonVanakkamtamila.vue'),

        },
        {
            path: '/Business/CompanyInfo',
            name: 'BusinessCompanyInfo',
            props: true,
            component: () => import('../Footer/Business/CompanyInfo.vue'),

        },
        {
            path: '/Business/ContactUs',
            name: 'BusinessContactUs',
            props: true,
            component: () => import('../Footer/Business/ContactUs.vue'),

        },
        {
            path: '/Business/Collabrate',
            name: 'BusinessCollabrate',
            props: true,
            component: () => import('../Footer/Business/CowithVanakkamtamila.vue'),

        },
        {
            path: '/Business/Promote',
            name: 'BusinessPromote',
            props: true,
            component: () => import('../Footer/Business/PromoteCompanyVanakkamtamila.vue'),

        },
        {
            path: '/SocialDeal/Howitworks',
            name: 'SocialDealHowitworks',
            props: true,
            component: () => import('../Footer/SocialDeal/HowitWorks.vue'),

        },
        {
            path: '/SocialDeal/Privacy',
            name: 'SocialDealPrivacy',
            props: true,
            component: () => import('../Footer/SocialDeal/privacyPolicy.vue'),

        },
        {
            path: '/CustomerService/FAQ',
            name: 'CustomerServiceFAQ',
            props: true,
            component: () => import('../Footer/CustomerServices/frequentlyAskquestion.vue'),

        },
        {
            path: '/CustomerService/Howitworks',
            name: 'CustomerServiceHowitworks',
            props: true,
            component: () => import('../Footer/CustomerServices/HowitWorks.vue'),

        },



        
       
    ]
})
export default router