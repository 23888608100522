<script>
import {computed, ref, onMounted } from 'vue';
import LocaleSwitcher from "../components/LocalSwitcher.vue";
import {useStore} from 'vuex';
import {RouterLink} from "vue-router";
import CountrySelect from '@/Header/CountrySelect'
import axios from 'axios';
import VendorLogin from '../components/VendorLogin.vue';
import UserLogin from '../components/UserLogin.vue';
import FeedBack from '../components/FeedbackPopup.vue';

export default {

  components: {
    LocaleSwitcher,
    RouterLink,
    CountrySelect,
    VendorLogin,
    UserLogin,
    FeedBack

  },
  props: {
    selectedCity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      eventValue: null,
      logoData: [],
      selectedLanguage:''
    };
  },
  computed: {
    userLogin() {
      const userLoginstatus = sessionStorage.getItem('isUserLogin');
      return userLoginstatus;
    },

  },
  methods: {
    handleCustomEvent(value) {
      this.eventValue = value; // Capture the emitted value
      // console.log(this.eventValue);
      this.$emit('custom-country-name', this.eventValue);
    },
    async getLogo() {

      try {
        // Perform an asynchronous operation, such as an API call, to fetch categories from the server
        const response = await axios.get(
            'logo'
        );
        this.logoData = response.data;


      } catch (error) {
      //  console.error('Error fetching logo data:', error);
        // Handle errors here
      }


    },
     async clickloginPopup() {
        await this.$refs.vendorLogin.show({
       
      })
      
    },
     async clickUserloginPopup() {
      await this.$refs.userLogin.show({

      })
    }, 
    async clickFeedbackPopup() {
      await this.$refs.feedBack.show({

      })
    },
    handleLanguageselect(value){
      this.selectedLanguage = value;
      this.$emit('getselectedlanguage', this.selectedLanguage);
    }
  },
  setup() {
    const store = useStore();
    const userloginstatus = computed(() => store.getters.storedLogin);
    const loginuserid = computed(() => store.getters.storeUserId);
    const vendorloginstatus = computed(() => store.getters.storedvendorLogin);
    const loginvendorid = computed(() => store.getters.storeloggedInvendorId)
    // const customerbearerToken = computed(() => sessionStorage.getItem('bearerToken'));
    const customerbearerToken = computed(() => sessionStorage.getItem('bearerToken'));
    // const vendorbearerToken = computed(() => sessionStorage.getItem('vendorbearerToken'));
    const vendorbearerToken = computed(() => sessionStorage.getItem('vendorbearerToken'));
    const userData = ref([]);
    const addressData = ref([]);
    const vendorData = ref([]);
    const vendoraddressData = ref([]);
    const LoginUserLanguageCode = ref();
    // console.log(loginvendorid);
    // console.log(vendorloginstatus);
     async function fetchUserData() {
            // Retrieve the bearer token from storage
            const bearerToken = sessionStorage.getItem('bearerToken');
           // console.log('jenthan');
            // Set authorization header with the bearer token
            const headers = { Authorization: `Bearer ${bearerToken}` };

            try {
                const response = await axios.get('customer/customerdata', { headers }); // Replace with your API endpoint
                console.log('customer data',response.data);
                userData.value = response.data.user;
                addressData.value = response.data.address;
                LoginUserLanguageCode.value = userData.value.language;
               
                // this.fetchCityData(this.addressData.country_id);
            } catch (error) {
               // console.error('Error fetching user data:', error);
                
                

            }
        }

        async function fetchVendorData() {
          const vendorbearerToken = sessionStorage.getItem('vendorbearerToken');
     
          const headers = { Authorization: `Bearer ${vendorbearerToken}` };

            try {
                const response = await axios.get('vendor/vendordata', { headers }); // Replace with your API endpoint
                //console.log(response.data);
                vendorData.value = response.data.vendor;
                vendoraddressData.value = response.data.address;
                 LoginUserLanguageCode.value = vendorData.value.language;
                // this.fetchCityData(this.addressData.country_id);
            } catch (error) {
               // console.error('Error fetching user data:', error);
                
                

            }
        }

        onMounted(() => {
            
            fetchUserData();
            fetchVendorData();
            
        });



        
    return {
      userloginstatus,
      loginuserid,
      vendorloginstatus,
      loginvendorid,
      customerbearerToken, userData,vendorData,
      vendorbearerToken, fetchUserData, LoginUserLanguageCode

    };
  },

  mounted() {
    // const vendorbearerToken = sessionStorage.getItem('vendorbearerToken');
    // const customerbearerToken = sessionStorage.getItem('bearerToken');
    // console.log('vendor', vendorbearerToken , 'customer', customerbearerToken);
  },
};
</script>

<template>
   <VendorLogin ref="vendorLogin"></VendorLogin>
   <UserLogin ref="userLogin"></UserLogin> 
   <FeedBack ref="feedBack"></FeedBack> 
  <!--navbar start  -->
  <nav class="navbar navbar-expand-lg  text-white mt-1">
    <div id="navigation-bar" class="container-fluid">
      <a class="navbar-brand" href="#">Logo</a>

      <div class="navbar-collapse">
        <!-- Left links -->
        <ul id="leftnavBar" class="navbar-nav me-auto d-flex flex-row mt-3 mt-lg-0">
          <li class="nav-item text-center mx-2 mx-lg-1">
          
          </li>
          <li class="nav-item text-center mx-2 mx-lg-1">
            <RouterLink active-class="activeNav" class="nav-link" to="/">
              <i class="fa-sharp fa-solid fa-house"></i>
              {{ $t("home") }}
            </RouterLink>
          </li>
         
         
          <li class="nav-item text-center mx-2 mx-lg-1">
            <RouterLink active-class="activeNav" to="/event">
             
                <i class="fa-solid fa-calendar-days"></i>
                {{ $t("events") }}
            
            </RouterLink>
          </li>

          <li class="nav-item text-center mx-2 mx-lg-1">
            <RouterLink active-class="activeNav" to="/stores">
             
              <i class="fa-solid fa-store"></i>
                {{ $t("stores") }}
            
            </RouterLink>
          </li>
          <li v-if="$store.state.loggedIn && $store.state.isUserLoggedIn" id="reg"
              class="nav-item text-center mx-2 mx-lg-1">
            <RouterLink active-class="activeNav" class="nav-link" to="/user/profile">

             
                  <img v-if="userData.avatar != null" class="rounded-circle home-avatarimage"  :src="baseUrl + userData.avatar" alt="" style="width: 40px; height: 40px; margin-top: -7px;">
                  <img v-else class="rounded-circle home-avatarimage" :src="default_userImage" alt="" style="width: 40px; height: 40px; margin-top: -7px;">
                  {{ userData.first_name }}
               
            </RouterLink>

          </li>

            <li  id="reg" v-else-if="!$store.state.isUserLoggedIn" class="nav-item text-center mx-2 mx-lg-1">
        
                <a class="nav-link" href="javascript:void(0);" @click="clickUserloginPopup">
                    <i class="fa-solid fa-user"></i>
                    {{ $t("user") }}
                </a>
        
            </li> 

          
          <li v-if="$store.state.isUserLoggedIn && $store.state.vendorloggedIn" id="reg"
              class="nav-item text-center mx-2 mx-lg-1">
            <RouterLink active-class="activeNav" to="/vendor/dashboard" class="nav-link">
              
                <img v-if="vendorData.avatar != null" class="rounded-circle home-avatarimage"  :src="baseUrl + vendorData.avatar" alt="" style="width: 40px; height: 40px; margin-top: -7px;">
                <img v-else class="rounded-circle home-avatarimage" :src="default_userImage" alt="" style="width: 40px; height: 40px; margin-top: -7px;">
                {{ vendorData.first_name }}
              
            </RouterLink>
          </li>
         

           <li v-else-if="!$store.state.isUserLoggedIn" id="reg" class="nav-item text-center mx-2 mx-lg-1">
             
                  <a class="nav-link" href="javascript:void(0);" @click="clickloginPopup">
                     <i class="fa-solid fa-user"></i>
                    {{ $t("vendor") }}
                  </a>
             
              </li> 
            <li  class="nav-item text-center mx-2 mx-lg-1">
        
                  <a class="nav-link" href="javascript:void(0);" @click="clickFeedbackPopup">
                      <i class="fa-solid fa-comment"></i>
                      {{ $t("feedback") }}
                  </a>
        
              </li> 
            
        </ul>
        <!-- Left links -->


        <!-- Right links -->
        <ul id="rightnavBar" class="navbar-nav ms-auto d-flex flex-row mt-3 mt-lg-0">
          <li id="languages" class="nav-item text-center mx-2 mx-lg-1 languages">
            
            <LocaleSwitcher :LoginUserLanguageCode="LoginUserLanguageCode" @selected-language-event="handleLanguageselect"/>
          </li>
          <li id="country-name" class="nav-item text-center mx-2 mx-lg-1 country-name" style="width: 211px;">
            <CountrySelect :selectedCity="selectedCity" @custom-event-name="handleCustomEvent"/>
          </li>
        </ul>
        <!-- Right links -->
      </div>
    </div>
  </nav>

  <!--nav bar end -->


</template>

<style scoped>
.navbar {
  --bs-white: #fff;
  text-align: left;
  margin-left: 0;
  font-size: 1rem;
  padding: 1rem 0;
  margin-top: -15px !important;
}

.navbar-collapse ul li {
  /* height: 75px; */

  border-color: aliceblue;
}

.navbar-collapse ul li a i {
  font-size: 24px;

}

.navbar-collapse .navbar-nav .nav-item a {
  display: contents;
  color: #071964;

}

.navbar-collapse ul {
  margin-left: 5%;
  column-gap: 9px;
}

.navbar-collapse .navbar-nav .activeNav {
  background: #0081e9;
  font-weight: 600;
  border-radius: 4px;
  /* height: 79px; */
  color: #ffff;
  text-decoration: none;
  /* margin-top: -14px; */
}

.navbar-collapse .navbar-nav a {
  text-decoration: none;

}

@media screen and (max-width: 576px) {
  #leftnavBar {
       position: fixed;
    z-index: 1;
    bottom: 23px;
    width: 100%;
    background: white;
    margin-left: -24px;
  }

  .navbar-collapse ul {
    margin-left: 5%;
    column-gap: 4px;
  }

  #leftnavBar li {
    margin-top: 10px;

  }

  #leftnavBar .nav-item a {
    display: contents;
    /* color: #071964;  */
    font-size: 11px;
  }

  #rightnavBar {
    margin-top: 2rem !important;
    margin-left: -16px !important;
    left: 4px;
    top: -7px;

  }

  .navbar {
    background: none;
    text-align: left;
    margin-left: 0;
    font-size: 0.1rem;
    padding: 8px 0;
    margin-top: 1rem;
  }

}

#leftnavBar a {
  padding-left: 10px;
  padding: 20px;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
#navigation-bar ul li {
    cursor: pointer;
    padding: 10px; 
}
}

@media screen and (min-width: 576px) and (max-width: 768px){
#navigation-bar ul li {
    cursor: pointer;
    padding: 7px; 
}

.user-data span{
    font-size: 18px;
}
}

.user-data{
    margin-top: -8px;
}

@media screen and (max-width: 576px) {
.user-data{
    margin-top: -2px;
}
.avatar {
  vertical-align: middle;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
}

@media screen and (min-width: 370px) and (max-width: 400px){

#leftnavBar li {
    margin-top: 10px;
    padding: 0px;
     margin-right: 10px !important;
}

.home-avatarimage{
  display: block;
  margin-left: 35px;
}
}

@media screen and (max-width: 370px){

#leftnavBar li {
    margin-top: 10px;
    padding: 0px;
     margin-right: 6px !important;
}
.home-avatarimage{
  display: block;
  margin-left: 31px;
}
}
@media screen and (min-width: 400px) and (max-width: 576px){
#leftnavBar li {
    margin-top: 10px;
    padding: 2px;
    margin-right: 10px !important;
}
.home-avatarimage{
  display: block;
  margin-left: 40px;
}
}
</style>