<template>
    <select  v-model="$i18n.locale" class="form-select" @change="updateLanguage">
        <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
            {{ getLanguageFullName(locale) }}
        </option>
    </select>
</template>

<script>
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import router from '../router/index';
export default {
    name: "LocaleSwitcher",
    data() {
        return { locales: ["fr", "en","du"],
        cookieLanguage:''
       // $i18n.locale: this.LoginUserLanguageCode

     };
      
    },
    props: {
        LoginUserLanguageCode: {
           type: String,
            default: "en"
        },
      
    },
     computed: {
       storedLoginUserLanguage() {
            const store = useStore();

            return store.getters.getUserLanguageCode;
        },

    },
     watch: {
        LoginUserLanguageCode(newValue) {
            // Update the selectedLocale when LoginUserLanguageCode changes
            this.$i18n.locale = newValue;
        },
        cookieLanguage(newValue){
            Cookies.set("CookiesLanguageCode", newValue, { expires: 6 });
            this.$emit('selected-language-event', newValue);
            router.push('/');
            window.location.reload();

        }
    },
    methods: {
        updateLanguage(){
            this.cookieLanguage = this.$i18n.locale;
                // Store the selected language in a cookie with a 6-day expiration
                
           
        },
        getLanguageFullName(locale) {
            // Add logic here to return the full name based on the locale
            switch (locale) {
                case "fr":
                    return "French";
                case "en":
                    return "English";
                case "du":
                    return "Dutch";
                default:
                    return "";
            }
        },
        
    },
     mounted() {
      // console.log('cookieLanguage',Cookies.get("CookiesLanguageCode"))
       if(Cookies.get("CookiesLanguageCode")){
        this.$i18n.locale = Cookies.get("CookiesLanguageCode"); 
       }
      // 
    }
};
</script>
